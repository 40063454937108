






















































import { PatientVm } from "@/api/api";
import { Component, Vue } from "vue-property-decorator";
import EditProfile from "./components/Edit.vue";
import yearsHelper from "@/services/helper";

import { profileModule } from "@/store";

@Component({
  components: {
    EditProfile,
  },
})
export default class Profile extends Vue {
  getYears = yearsHelper.getYears;

  get profiles(): PatientVm[] {
    return profileModule.state.patients;
  }

  get profile(): PatientVm | null {
    return profileModule.state.patient;
  }

  selectProfile(patient: PatientVm) {
    profileModule.mutations.setPatient(patient);
  }
}
