
































































































































































































































































































import { Genders, PatientClient, PatientVm } from "@/api/api";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Inputmask from "inputmask";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

import ConfirmPhone from "./ConfirmPhone.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { rules } from "./Edit.Validation";

import { profileModule } from "@/store";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import AuthService from "@/services/auth.service";

@Component({
  components: {
    DatePicker,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Home extends Vue {
  @Prop({ required: true })
  profile!: PatientVm;

  profileToEdit: PatientVm | null = null;
  phoneValidationToken: string | null = null;

  Genders = Genders;
  rules = rules;

  confirmEmailRepeatSending = false;

  mounted() {
    this.profileToEdit = { ...this.profile };
    this.$nextTick().then(() => {
      const im = new Inputmask("+7 (999) 999 9999", { placeholder: "*" });
      im.mask(this.$refs.phone as HTMLElement);

      const im_inn = new Inputmask("999999999999", { placeholder: "*" });
      im_inn.mask(this.$refs.inn as HTMLElement);

      const im_password = new Inputmask("9999 999999", { placeholder: "*" });
      im_password.mask(this.$refs.passport as HTMLElement);

      const im_snils = new Inputmask("999-999-999 99", { placeholder: "*" });
      im_snils.mask(this.$refs.snils as HTMLElement);
    });
  }

  get profileDataChanged() {
    if (
      this.profile.phoneNumber !== this.profileToEdit?.phoneNumber &&
      (this.phoneValidationToken == null || this.phoneValidationToken == "")
    ) {
      return false;
    }
    return JSON.stringify(this.profile) !== JSON.stringify(this.profileToEdit);
  }

  @Watch("profile")
  profileChanged() {
    this.profileToEdit = { ...this.profile };
  }

  updateProfile() {
    profileModule.actions
      .updatePatientProfile({
        ...this.profileToEdit,
        phoneConfirmationToken: this.phoneValidationToken!,
      })
      .then(() => {
        this.phoneValidationToken = null;
        Swal.fire({ text: "Данные сохранены", icon: "success" });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  notAfterToday(date: Date) {
    return date > new Date();
  }

  private showPhoneConfirmModal(instance: ConfirmPhone) {
    return Swal.fire({
      html: '<div id="VueSweetAlert2"></div>',
      showConfirmButton: true,
      focusConfirm: false,
      confirmButtonText: "Подтвердить",
      confirmButtonColor: "#62cb31",

      willOpen: () => {
        instance.$mount();
        document.getElementById("VueSweetAlert2")?.appendChild(instance.$el);
      },
      preConfirm: () => instance.code,
    });
  }

  private getConfirmPhoneComponent() {
    const ComponentClass = Vue.component("confirmPhone");
    const instance = new ComponentClass({
      propsData: {
        phone: this.profileToEdit?.phoneNumber,
        patientId: this.profileToEdit?.patientId,
      },
    }) as ConfirmPhone;
    return instance;
  }

  async repeatSendConfirmEmail() {
    if (this.confirmEmailRepeatSending) return;
    this.confirmEmailRepeatSending = true;
    const client = new PatientClient();
    await client.setAuthToken(AuthService.refreshToken);
    client
      .sendconfirmemail({ patientId: this.profileToEdit?.patientId })
      .then(() => {
        Swal.fire({ text: "письмо с подтверждением выслано", icon: "success" });
        this.confirmEmailRepeatSending = false;
      });
  }

  async confirmPhone() {
    const client = new PatientClient();
    await client.setAuthToken(AuthService.refreshToken);
    client
      .sendphoneconfirmationcode({
        phoneNumber: this.profileToEdit?.phoneNumber,
        patientId: this.profileToEdit?.patientId,
      })
      .then(() => {
        const instance = this.getConfirmPhoneComponent();

        this.showPhoneConfirmModal(instance).then((value) => {
          if (value.isConfirmed && !!value.value) {
            client
              .getphoneconfirmationtoken({
                phoneNumber: this.profileToEdit?.phoneNumber,
                patientId: this.profileToEdit?.patientId,
                code: value.value,
              })
              .then((token) => {
                this.phoneValidationToken = token;
              });
          }
        });
      });
  }
}
