import { configure } from "vee-validate";

configure({
  classes: {
    valid: "has-success",
    invalid: "is-invalid",
  },
});

import { extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);
extend("regex", regex);

import { localize } from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
localize("ru", ru);

localize({
  en: {
    names: {
      FirstName: "FirstName",
      SecondName: "SecondName",
      Patronymic: "Patronymic",
      Email: "Email",
      Phone: "Phone",
      BirthDay: "BithDay",
    },
  },
  ru: {
    names: {
      FirstName: "Имя",
      SecondName: "Фамилия",
      Patronymic: "Отчество",
      Email: "Email",
      Phone: "Сотовый телефон",
      BirthDay: "День рождения",
    },
    fields: {
      FirstName: {
        required: "Введите имя",
        regex: "Допускаются только буквы",
      },
      SecondName: {
        required: "Введите фамилию",
        regex: "Допускаются только буквы",
      },
      Patronymic: {
        regex: "Допускаются только буквы",
      },
      Email: {
        email: "некорректный email",
      },
      Phone: {
        required: "требуется телефон",
        regex: "некорректный формат телефона",
      },
      BirthDay: {
        required: "Введите день рождения",
      },
    },
  },
});

export const rules = {
  FirstName: {
    required: true,
    regex: /^([a-zA-Zа-яА-ЯёЁ -]+)$/,
  },
  SecondName: {
    required: true,
    regex: /^([a-zA-Zа-яА-ЯёЁ -]+)$/,
  },
  Patronymic: {
    regex: /^([a-zA-Zа-яА-ЯёЁ -]+)$/,
  },
  Email: {
    email: true,
  },
  Phone: {
    required: true,
    regex: /^\+7 \([0-9]{3}\) [0-9]{3} [0-9]{4}$/,
  },
};
